$primary-blue: #0881cc;
$secondary-blue: #1a98ff;
$accent-blue: #0096c7;
$light-blue: #aad8ff;

$primary-gray: #424242;
$secondary-gray: #616161;
$accent-gray: #9E9E9E;
$light-gray: #E0E0E0;

$accent-green: #4CAF50;
$accent-orange: #FF9800;
$accent-red: #B30000;

:root {
  // --primary-blue: #0881cc;
  // --secondary-blue: #1a98ff;
  // --accent-blue: #0096c7;
  // --light-blue: #aad8ff;

  // --primary-blue: #004182;
  // --secondary-blue: #3369b0;
  // --accent-blue: #0073e6;
  // --light-blue: #a9c4e2;

  --primary-blue: #004182;
  --secondary-blue: #3369b0;
  --accent-blue: #0073e6;
  --light-blue: #d5e4f7;

  --primary-gray: #424242;
  --secondary-gray: #616161;
  --accent-gray: #9E9E9E;
  --light-gray: #E0E0E0;

  --accent-green: #4CAF50;
  --accent-orange: #FF9800;
  --accent-red: #B30000;
}