.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f3f4f6;
    overflow: hidden;
    animation: fadeIn 0.8s;

    & .error-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 80%;
        animation: slideUp 0.8s;
    }

    
    & .error-image img {
        width: 100px;
        margin-bottom: 1rem;
    }

    & h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        color: #2c3e50;
    }

    & p {
        margin-bottom: 2rem;
        text-align: center;
        color: #7f8c8d;
    }

    & a {
        background-color: #3498db;
        color: white !important;
        text-decoration: none !important;
        padding: 0.7rem 2rem;
        border-radius: 20px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
