.text-button {
  background: transparent;
  border: none;
  outline: none !important;
  cursor: pointer;
}
button {
  outline: none !important;
}
/* hr {
    color: #000;
    height: 2px;
    background: #000;
} */
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  accent-color: var(--primary-blue);
}
.link-button {
  background: transparent !important;
  color: var(--primary-blue);
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.error-message {
  font-size: 12px;
  color: #ff0000;
}

.success-message {
  font-size: 12px;
  color: #368d04;
}

.hide-error .error-section {
  display: none;
}

.no-data-found-label {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  padding: 15vh 0px;
  flex-wrap: wrap;
}

textarea {
  resize: none;
}

.modal-header {
  align-items: center;
}

.disabled {
  background-color: #e9ecef;
}