.static-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background:
    linear-gradient(
      to bottom,
      hsl(240, 38.46%, 94.9%) 0%,
      hsl(238.49, 38.87%, 94.74%) 8.1%,
      hsl(234.81, 39.9%, 94.29%) 15.5%,
      hsl(230.35, 41.24%, 93.58%) 22.5%,
      hsl(226.07, 42.64%, 92.66%) 29%,
      hsl(222.37, 43.98%, 91.57%) 35.3%,
      hsl(219.33, 45.18%, 90.33%) 41.2%,
      hsl(216.88, 46.25%, 89.01%) 47.1%,
      hsl(214.91, 47.18%, 87.63%) 52.9%,
      hsl(213.35, 48.01%, 86.24%) 58.8%,
      hsl(212.1, 48.72%, 84.9%) 64.7%,
      hsl(211.12, 49.32%, 83.66%) 71%,
      hsl(210.37, 49.82%, 82.59%) 77.5%,
      hsl(209.83, 50.19%, 81.74%) 84.5%,
      hsl(209.5, 50.43%, 81.18%) 91.9%,
      hsl(209.39, 50.52%, 80.98%) 100%
    ); */
  z-index: -1;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
